import React from "react";
import { Link } from "gatsby";
import Richtext from "./Richtext";
import Container from "./Container";
import FormModal from "./FormModal";
import slugify from "../util/slugify";
import getImageSrc from "../util/getImageSrc";

function Banner({
  title,
  description,
  image,
  buttonTo,
  filterOptions,
  filterSet,
  filterPlaceholder = "All Categories",
  buttonLabel = "Learn More",
  jsOn = false,
  form,
  formHeight = 570, //default for demo form. Contact form uses 540.
  formLabel,
  target="_self",
  bannerClassName="banner"
}) {
  const bg = getImageSrc(image);
  const btn_clss = typeof form === "undefined" ? 'button white solid' : 'button white outline';
  const btn_form_clss = 'button white solid';
  const btn_form_label = formLabel ? formLabel : buttonLabel
  return (
    <header className={bannerClassName} style={{ backgroundImage: `url(${bg})` }}>
      <div className="txt">
        <Container size="md">
          <Richtext align="center">
            <h1>{title}</h1>
            {typeof description !== "undefined" && <p>{description}</p>}
            {typeof form !== "undefined" && (
              <FormModal src={form} buttonType={btn_form_clss} buttonLabel={btn_form_label} height={formHeight} />
            )}
            {typeof buttonTo !== "undefined" && (
              <Link className={btn_clss} to={buttonTo}  target={target}>
                {buttonLabel}
              </Link>
            )}
          </Richtext>
          {filterOptions && (
            <select
              placeholder={filterPlaceholder}
              onChange={(e) => filterSet(e.target.value)}
              onBlur={(e) => filterSet(e.target.value)}
              className="textfield"
              disabled={!jsOn}
            >
              <option value="">{filterPlaceholder}</option>
              {filterOptions.map((option) => (
                <option key={slugify(option)}>{option}</option>
              ))}
            </select>
          )}
        </Container>
      </div>
      <span className="tint tint1" />
      <span className="tint tint2" />
      <span className="tint tint3" />
    </header>
  );
}

export default Banner;
